import { createSlice } from '@reduxjs/toolkit'

export const headerSlice = createSlice({
  name: 'header',
  initialState: {
    pageTitle: 'Dashboard', // current page title state management
  },
  reducers: {
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload.title
    },

    showNotification: (state, action) => {
      state.newNotificationMessage = action.payload.message
      state.newNotificationStatus = action.payload.status
    },
  },
})

export const { setPageTitle, showNotification } = headerSlice.actions

export default headerSlice.reducer
