import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getTransactionPagination = createAsyncThunk(
  'transactions/getTransactionPagination',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/transaction`, { params: { page, counting: page === 1 ? '1' : null } })
      return response.data.data
    } catch (err) {
      return rejectWithValue({ transactions: [], count: false })
    }
  }
)

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    isLoading: true,
    transactions: [],
    pagination: {
      count: 0,
      currentPage: 1,
      next: false,
      prev: false,
    },
  },
  reducers: {
    addTransaction: (state, action) => {
      state.transactions = [action.payload, ...state.transactions]
      state.pagination.count += 1

      if (state.transactions.length > 20) {
        state.pagination.next = true
      }
    },
    deleteTransaction: (state, action) => {
      state.transactions = state.transactions.filter((transaction) => transaction.id !== action.payload.id)
      state.pagination.count -= 1
    },
    updateTransaction: (state, action) => {
      state.transactions = state.transactions.map((transaction) => {
        if (transaction.id === action.payload.id) {
          return { ...transaction, ...action.payload }
        } else {
          return transaction
        }
      })
    },
    nextPage: (state) => {
      if (state.pagination.next) {
        state.pagination.currentPage += 1
      }
    },
    prevPage: (state) => {
      if (state.pagination.prev) {
        state.pagination.currentPage -= 1
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionPagination.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTransactionPagination.fulfilled, (state, action) => {
        const { transactions, count } = action.payload
        state.transactions = transactions

        if (action.payload.count !== false) {
          state.pagination.count = count
        }

        if (state.pagination.currentPage <= 1) {
          state.pagination.prev = false
        } else {
          state.pagination.prev = true
        }

        if (state.pagination.currentPage * 50 >= state.count || transactions.length < 20) {
          state.pagination.next = false
        } else {
          state.pagination.next = true
        }

        state.isLoading = false
      })
      .addCase(getTransactionPagination.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { nextPage, prevPage, addTransaction, deleteTransaction, updateTransaction } = transactionSlice.actions

export default transactionSlice.reducer
