import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import leadsSlice from '../features/leads/leadSlice'
import gallonSlice from '../features/gallon/gallonSlice'
import transactionSlice from '../features/transaction/transactionSlice'

export default configureStore({
  reducer: {
    header: headerSlice,
    modal: modalSlice,
    lead: leadsSlice,
    gallon: gallonSlice,
    transaction: transactionSlice,
  },
})
