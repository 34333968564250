import axios from 'axios'

const checkAuth = () => {
  /*  Getting token value stored in localstorage, if token is not present we will open login page 
    for all internal dashboard routes  */
  const TOKEN = localStorage.getItem('token')
  const PUBLIC_ROUTES = ['login', 'register']

  const isPublicPage = PUBLIC_ROUTES.some((r) => window.location.href.includes(r))

  if (!TOKEN && !isPublicPage) {
    window.location.href = '/login'
    return
  }

  axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`
  axios.defaults.validateStatus = () => true

  axios.interceptors.response.use(
    function (response) {
      if (response.status === 403) {
        localStorage.removeItem('token')
        window.location.href = '/login'
        return
      }

      if (response.status !== 200 && response.data.message) {
        return Promise.reject(new Error(response.data.message))
      }
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )
  return TOKEN
}

export default checkAuth
