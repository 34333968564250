import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

// Define the async thunk for fetching gallon data
export const getGallons = createAsyncThunk('gallon/getGallons', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/gallon')
    return response.data.data
  } catch (err) {
    return rejectWithValue([])
  }
})

// Create a slice for the gallons
const gallonSlice = createSlice({
  name: 'gallons',
  initialState: {
    isLoading: true,
    gallons: [],
  },
  reducers: {
    addNewGallon: (state, action) => {
      state.gallons = [action.payload, ...state.gallons]
    },
    deleteGallon: (state, action) => {
      state.gallons = state.gallons.filter((gallon) => gallon.code !== action.payload.index)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGallons.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getGallons.fulfilled, (state, action) => {
        state.gallons = action.payload
        state.isLoading = false
      })
      .addCase(getGallons.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { addNewGallon, deleteGallon } = gallonSlice.actions

export default gallonSlice.reducer
