import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getCustomerPagination = createAsyncThunk(
  'customers/getCustomerPagination',
  async (page, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/customer`, { params: { page, counting: page === 1 ? '1' : null } })
      return response.data.data
    } catch (err) {
      return rejectWithValue({ customers: [], count: false })
    }
  }
)

export const leadsSlice = createSlice({
  name: 'customers',
  initialState: {
    isLoading: true,
    count: 0,
    customers: [],
    pagination: {
      currentPage: 1,
      next: false,
      prev: false,
    },
  },
  reducers: {
    addNewLead: (state, action) => {
      state.count += 1
      state.customers = [action.payload, ...state.customers]

      if (state.customers.length > 20) {
        state.pagination.next = true
      }
    },
    deleteLead: (state, action) => {
      state.customers = state.customers.filter((customer) => customer.code !== action.payload.index)
      state.count -= 1
    },
    nextPage: (state) => {
      if (state.pagination.next) {
        state.pagination.currentPage += 1
      }
    },
    prevPage: (state) => {
      if (state.pagination.prev) {
        state.pagination.currentPage -= 1
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerPagination.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCustomerPagination.fulfilled, (state, action) => {
        const { customers, count } = action.payload
        state.customers = customers

        if (action.payload.count !== false) {
          state.count = count
        }

        if (state.pagination.currentPage <= 1) {
          state.pagination.prev = false
        } else {
          state.pagination.prev = true
        }

        if (state.pagination.currentPage * 20 >= state.count || customers.length < 20) {
          state.pagination.next = false
        } else {
          state.pagination.next = true
        }

        state.isLoading = false
      })
      .addCase(getCustomerPagination.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { addNewLead, deleteLead, nextPage, prevPage } = leadsSlice.actions

export default leadsSlice.reducer
